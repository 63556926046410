import React, { CSSProperties, FC } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

interface ButtonProp {
  variant: 'red' | 'black'
  size?: 'sm' | 'lg'
  path: string
  type?: 'button' | 'submit' | 'reset'
  style?: CSSProperties
}

const IButton = styled.button<{ variant: string; size: string }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) =>
    props.size === 'lg' ? '1.25rem 3.25rem' : '0.75rem 2rem'};
  border: none;
  border-radius: 8px;
  background-color: ${(props) =>
    props.variant === 'red' ? props.theme.colors.red : props.theme.colors.black};
  color: white;
  font-size: ${(props) => (props.size === 'lg' ? '18px' : '14px')};
  font-weight: 600;
  letter-spacing: 1px;
  cursor: pointer;
  text-transform: uppercase;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  a {
    color: white;
    text-decoration: none;
  }

  &:hover {
    background-color: ${(props) =>
      props.variant === 'red' ? '#B80000' : '#393939'};
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(255, 0, 0, 0.5); /* Visible focus indicator */
  }

  &:active {
    transform: scale(0.95);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  }
`

const StyledLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
`

const Button: FC<ButtonProp> = ({
  variant,
  children,
  size = 'sm',
  path,
  type,
  style,
}) => {
  return (
    <StyledLink to={path}>
      <IButton variant={variant} size={size} type={type} style={style}>
        {children}
      </IButton>
    </StyledLink>
  )
}

export default Button
