import React, { FC } from 'react'
import { useScreenClass, Hidden } from 'react-grid-system'
import styled from 'styled-components'
import FACULTY from '../../assets/networking.jpg'
import SCHEDULE from '../../assets/schedule.jpg'
import { Button } from '../shared'

const variantOptions = {
  schedule: {
    title: 'Schedule',
    headline:
      'For the working professional, we meet evenings, after your work is done.',
    description: (
      <p>
        We make it possible for you develop your podcast and your skills
        comprehensively, over the course of two, full academic semesters. Once a week, every week (except Thanksgiving and
        Christmas/New Years). And eight months-plus of continual mentoring.
        <br />
      </p>
    ),
  },
  faculty: {
    title: 'Faculty',
    headline: 'You are the working professional. So are we.',
    description: (
      <p>
        Professionalism is paramount. Our faculty has been in the podcasting
        space since its inception in 2004. And we’ve created, developed and
        launched podcasts big & small. From{' '}
        <em>Here’s The Thing with Alec Baldwin</em>, to <em>Bloomberg News</em>;{' '}
        <em>You And Me Both with Hilary Clinton</em>, to the{' '}
        <em>Icahn School of Medicine at Mt. Sinai</em>, and numerous indies in
        between.
      </p>
    ),
  },
}

const SlideText = styled.div<{ isDesktop: boolean }>`
  height: ${(props) => (props.isDesktop ? '500px' : '600px')};
  background-color: ${(props) =>
    props.isDesktop ? '' : 'rgba(239,239,239, 0.70)'};
  width: ${(props) => (props.isDesktop ? '45%' : '100%')};
  padding: 2rem;
  text-align: left;
  margin: ${(props) => (props.isDesktop ? '0 0 0 2rem' : '0')};

  h5 {
    font-size: ${(props) => (props.isDesktop ? '22px' : '20px')};
    margin-top: 0;
  }

  h3 {
    color: ${(props) => props.theme.colors.brightRed};
    font-size: ${(props) => (props.isDesktop ? '30px' : '28px')};
    width: ${(props) => (props.isDesktop ? '80%' : '')};
  }

  p {
    line-height: 1.6rem;
    margin-bottom: 1.75rem;
  }
`
interface Prop {
  variant: 'schedule' | 'faculty'
}

const Slide: FC<Prop> = ({ variant }) => {
  const screenClass = useScreenClass()
  const isDesktop = ['lg', 'xl'].includes(screenClass)

  const title = variantOptions[variant].title
  const headline = variantOptions[variant].headline
  const description = variantOptions[variant].description
  return (
    <>
      <SlideText isDesktop={isDesktop}>
        <h5>{title}</h5>
        <h3>{headline}</h3>
        {description}
        <Button
          variant="red"
          path={variant === 'schedule' ? '/apply' : '/faculty'}
        >
          {variant === 'schedule' ? 'Apply Now' : 'Learn More'}
        </Button>
      </SlideText>
      <Hidden xs sm md>
        <img
          src={variant === 'schedule' ? SCHEDULE : FACULTY}
          alt=""
          style={{
            width: '46%',
            height: isDesktop ? '500px' : '600px',
            objectFit: 'cover',
            margin: '0 auto',
            objectPosition: 'top center',
          }}
        />
      </Hidden>
    </>
  )
}

export default Slide
