import React, { FC } from 'react'
import { Col, Row, useScreenClass } from 'react-grid-system'
import styled from 'styled-components'
import { Briefcase, Diagram3, Reception4 } from 'react-bootstrap-icons'

const Dotted = styled.hr<{ isDesktop: boolean }>`
  border: none;
  border-right: 2px dashed rgba(227, 227, 227, 0.25);
  background-color: transparent;
  height: ${(props) => (props.isDesktop ? '570px' : '98%')};
  width: 1px;
  position: absolute;
  top: ${(props) => (props.isDesktop ? '-32%' : '-10px')};
`
const Content = styled.div<{ isDesktop: boolean }>`
  max-width: 355px;
  margin-left: 1rem;
  margin-bottom: ${(props) => (props.isDesktop ? '' : '5rem')};
  h3 {
    margin-top: 0;
    font-size: ${(props) => (props.isDesktop ? '26px' : '20px')};
    color: white;
  }

  p {
    color: ${(props) => props.theme.colors.lightGray};
    line-height: 1.6rem;
  }
`

const Services: FC = () => {
  const screenClass = useScreenClass()
  const isDesktop = ['lg', 'xl'].includes(screenClass)

  return (
    <>
      <Row justify="around" align="start" style={{ margin: '2rem 0' }}>
        <Col
          xs={12}
          sm={12}
          md={4}
          lg={4}
          style={{
            maxWidth: isDesktop ? '480px' : '100%',
            position: 'relative',
          }}
        >
          <Dotted isDesktop={isDesktop} />
          <Content isDesktop={isDesktop}>
            <Reception4 color="white" size={36} />
            <h3>Intensive Coursework</h3>
            <p>
              Conceive, pitch, script, record, edit and refine your podcast
              through class workshops and targeted assignments covering
              everything you need to know: once a week, over 30 weeks. Workshop
              your ideas within a comprehensive curriculum based on best
              practices teaching modules in audio journalism and production
            </p>
          </Content>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={4}
          lg={4}
          style={{
            maxWidth: isDesktop ? '480px' : '100%',
            position: 'relative',
          }}
        >
          <Dotted isDesktop={isDesktop} />
          <Content isDesktop={isDesktop}>
            <Diagram3 color="white" size={36} />
            <h3>Networking and Mentorship</h3>
            <p>
              Receive instruction and one-on-one editorial guidance from podcast
              and audio professionals, as well as critiques and
              behind-the-scenes insights from guest speakers who are leaders in
              the podcast industry. Take advantage of internship opportunities
              with popular podcasts.
            </p>
          </Content>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={4}
          lg={4}
          style={{
            maxWidth: isDesktop ? '480px' : '100%',
            position: 'relative',
          }}
        >
          <Dotted isDesktop={isDesktop} />
          <Content isDesktop={isDesktop}>
            <Briefcase color="white" size={36} />
            <h3>Industry Standard</h3>
            <p>
              Complete a finished, ready-to-market pilot—or several episodes—of
              your podcast and launch it under our guidance during the second
              semester. Prepare to work in this high-demand and growing field
              with the production skills and tools you’ll acquire through the program.
            </p>
          </Content>
        </Col>
      </Row>
    </>
  )
}

export default Services
