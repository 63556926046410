import { nanoid } from 'nanoid'
import {
  BLAKE,
  HALLE,
  MARTHA,
  OMAMA,
  MANOLO,
  KAREEMA,
  JENNIFER,
  TANVI,
} from '../../assets/alumni'

export interface AlumniData {
  id: string
  name: string
  title: string
  image: string
  bio?: string
}

export const ALUMNI_DATA: AlumniData[] = [
  {
    id: nanoid(),
    name: 'Blake Pfeil',
    title: 'Producer',
    image: BLAKE,
    bio: `We are thrilled to announce that Blake has been nominated for Best Indie Podcast at The 2024 Ambies. Read more on Blake’s [website](https://www.blakepfeil.com/allamericanruins.html)`,
  },
  {
    id: nanoid(),
    name: 'Halle Hewitt',
    title: 'Producer',
    image: HALLE,
    bio: `Halle Hewitt is the production assistant of StoryCorps' One Small Step. She is also the sole producer of the upcoming podcast [CHROMA](https://www.instagram.com/chromapodcast/), a saturated collection of stories exploring people's relationships to colors. You can also listen to [Returning Home: Three Oneida Children Find a Final Resting Place](https://storycorps.org/stories/returning-home-three-oneida-children-find-a-final-resting-place/), a piece she pitched and helped produce for StoryCorps and NPR's Morning Edition.`,
  },
  {
    id: nanoid(),
    name: 'Omama Othman',
    title: 'Writer & Producer',
    image: OMAMA,
    bio: `Omama Othman is an audiovisual translator, writer, and audio producer living in Doha, Qatar. In the past, she worked for Teach for Qatar (part of the Teach for All network), Al Jazeera, Ajyal Film Festival, and Qatar Foundation for Education, Research, and Community Development. She's passionate about education, accessibility, and migrants' rights, especially in the Arab Gulf area. Omama has joined the Audio Podcast Fellows program to work on developing Ballads of Others -- a podcast about the places we leave that never leave us. The show will uncover intimate stories of people who have been othered in their own communities. Omama's [first audio piece](https://vimeo.com/showcase/8330067/video/533456158) was featured as an Official Select at On Air Fest 2021.`,
  },
  {
    id: nanoid(),
    name: 'Martha Pichey',
    title: 'Playwright & Producer',
    image: MARTHA,
    bio: `Martha Pichey has created WATERMARKS, stemming from her early fascination with whaling, and the gutsy women who joined their husbands at sea in the 1800s. Along with the podcast, shes writing a play with songs about the same subject called I AM A SHIP. \n\n Her first play, ASHES & INK, will be produced by womens theatre company Noras Playhouse in September of 2022. She continues to develop work as a member of the Playwrights and Directors Workshop at The Actors Studio. Martha has freelanced for a variety of publications, from Vanity Fair to The New York Times. Learn more about her past work at [www.marthapichey.com](https://marthapichey.com) 
    `,
  },
  {
    id: nanoid(),
    name: 'Kareema Bee',
    title: 'Founder of The HiveMind Unified',
    image: KAREEMA,
    bio: `Kareema Bee is a content creator from New York City. Her professional background as a producer spans from major TV, film, and digital productions, to creative development, representation, and social impact events. As a writer and actress, she has lent her talents to numerous residencies and placed as a semi-finalist in notable network writing fellowships. Her work can also be seen in animation, award shows and high-profile speeches. Through her production company MC Gran, she writes and produces content geared toward elevating underrepresented voices. By creating The HiveMind Unified, she provides resources to help people of color in Entertainment advance in their careers. For more, you can check out [kareemabee.com](http://kareemabee.com/)`,
  },
  {
    id: nanoid(),
    name: 'Jennifer Basset',
    title: 'Founder of Big Din',
    image: JENNIFER,
    bio: `Jennifer Shin Bassett is the Founder of [Big Din](http://bigdinproductions.com/), a podcast production company. After completing the audio podcast fellowship, she’s worked with iHeartMedia, Hello Sunshine, the LA Times Studio, Motherly Media, HarperCollins, and more. She's produced [The Motherly Podcast](https://podcasts.apple.com/us/podcast/the-motherly-podcast/id1449924733) (the winner of two 2021 Webby Awards and a recent 2022 Webby Honoree),[ Time Out: A Fair Play Podcast](https://www.iheart.com/podcast/1119-time-out-a-fair-play-podc-91037429/), [Talking Feds](https://www.talkingfeds.com/), [Talking Feds: Women at the Table](https://www.podpage.com/talking-feds-women-at-the-table-1/), and most recently, [The Healthy Baby Show](https://www.iheart.com/podcast/1119-the-healthy-baby-show-93537285/). Before working in audio production, she headed up global content at Spotify, startups, and advertising agencies.`,
  },
  {
    id: nanoid(),
    name: 'Manolo Morales',
    title: 'Podcast Producer',
    image: MANOLO,
    bio: `Manolo Morales is a graduate of the Craig Newmark Graduate School of Journalism. Before receiving his master's degree in journalism, he majored in English at John Jay College of Criminal Justice. He has previously interned for Latino USA. And was a summer fellow at KALW in San Francisco, California in 2019. He was also an AIR New Voices scholar in 2020. Last year, he produced a story for a Spanish speaking podcast called CUIR: Historias Disidentes.  He is currently a producer at WitnessDocs. Some of his produced pieces include [Nuestra Diva, Lorena Borjas and  Atlas Obscura](https://estonoesradio.mx/programas/cuir/temporada-1/nuestra-diva-lorena-borjas).`,
  },
  {
    id: nanoid(),
    name: 'Tanvi Kumar',
    title: 'Podcast Producer',
    image: TANVI,
    bio: `Tanvi Kumar was an Audio Podcast Fellow from 2020-2021. During this time, she worked on her independent podcast called Don't Look Away, a show about America's immigrant detention centers and the people they are designed to incarcerate. After completing the program, Tanvi continued to work on Don't Look Away, which was selected by the Rough Cut Collective as a pro bono project. Tanvi also joined the [AIR New Voices Scholars](https://airmedia.org/community/spotlight/tanvi-kumar) program in 2021, a program for emerging leaders in the field of audio. She is now the Production Fellow at [Live Wire Radio](https://www.livewireradio.org/). Tanvi is also a producer on a new project from [Outside Inc.](https://www.outsideinc.com/) and [House of Pod](https://www.houseofpod.org/) called The Daily Rally. `,
  },
]
