import React, { FC } from 'react'
import { Grid } from '../components/Guests'
import { GUESTS_DATA } from '../components/Guests/data'
import { SecondaryHero } from '../components/shared'

const Guests: FC = () => {
  return (
    <>
      <SecondaryHero>Guests</SecondaryHero>
      <Grid data={GUESTS_DATA} />
    </>
  )
}

export default Guests
