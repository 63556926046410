import React, { FC } from 'react';
import { Col, Container, Row, useScreenClass } from 'react-grid-system';
import styled from 'styled-components';

import AlumniBio from '../Alumni/AlumniBio';
import { AlumniData } from '../Alumni/data';
import { ImageBlock } from '../shared';

interface Props {
  data: AlumniData[];
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}

const Section = styled.section<{ isDesktop: boolean }>`
  text-align: center;
  margin: 0 auto;
  max-width: 1440px;
`;

const ItemContainer = styled.div<{ isDesktop: boolean }>`
  display: flex;
  flex-direction: ${({ isDesktop }) => (isDesktop ? 'row' : 'column')};
  align-items: center;
  ${({ isDesktop }) => !isDesktop && 'padding: 1rem 0;'}
`;

const Grid: FC<Props> = ({ data, xs = 12, sm = 12, md = 3, lg = 2.4, xl = 2.4 }) => {
  const screenClass = useScreenClass();
  const isDesktop = ['lg', 'xl'].includes(screenClass);

  return (
    <Section isDesktop={isDesktop}>
      <Container>
        <Row>
          {data.map((item) => (
            <Col
              key={item.name}
              xs={xs}
              sm={sm}
              md={md}
              lg={lg}
              xl={xl}
              style={{ marginBottom: '4rem' }}
            >
              <ItemContainer isDesktop={isDesktop}>
                <ImageBlock
                  title={item.bio ? undefined : item.name}
                  para={item.bio ? undefined : item.title}
                  src={item.image}
                  alt={`${item.name} portrait`}
                  color="black"
                  imgStyles={{
                    height: '200px',
                    width: '200px',
                    maxHeight: '250px',
                    maxWidth: '250px',
                  }}
                />
                {item.bio && (
                  <AlumniBio name={item.name} title={item.title} bio={item.bio} />
                )}
              </ItemContainer>
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  );
};

export default Grid;
