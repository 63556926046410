import React from 'react';
import styled from 'styled-components';
import { SectionTitle } from '../shared'
import briefcase from '../../assets/briefcase.svg'
import building from '../../assets/building.svg'
import hands from '../../assets/hands.svg'
import heart from '../../assets/heart.svg'
import money from '../../assets/money.svg'
import globe from '../../assets/globe.svg'

const BenefitsWrapper = styled.section`
  text-align: center;
  padding: 2rem 15% 4rem 15%;

  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .benefits-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    justify-content: center;
  }

  .benefit-tile {
    background-color: #fff;
    border: 1px solid #990000; /* Updated tile border color */
    border-radius: 8px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .tile-icon {
    width: 40px;
    height: 40px;
    margin-bottom: 5px;
    display: flex;
    align-items: flex-start;
  }

  .tile-icon img {
    max-width: 100%;
    max-height: 100%;
  }

  h3 {
    font-size: 20px;
    margin-bottom: 5px;
    text-align: left;
  }

  p {
    font-size: 16px;
    color: #666;
    text-align: left;
  }

  @media (max-width: 768px) {
    .benefits-container {
      grid-template-columns: 1fr;
    }
  }
`;

const Benefits = () => {
  const benefits = [
    {
      title: 'Remote Option',
      description: 'Podcast Fellows is a global program that brings together voices from all parts of the world. We’ve brought light to voices from Nigeria, Qatar, the Shinnecock Nation, Alaska and more.',
      icon: globe,
    },
    {
      title: 'In-Person Studios',
      description: 'We are proud to offer two fully functional studios that are available for use throughout the semester. Practice podcasting like a professional. ',
      icon: building,
    },
    {
      title: 'Community',
      description: 'Podcast Fellows is committed to building a safe and inclusive spaces for every person, centered around open, honest and considerate communication.',
      icon: heart,
    },
    {
      title: 'Networking Events',
      description: 'We offer discounts and free attendance to many events as a part of tuition including PodFest, OnAirFest and more! ',
      icon: hands,
    },
    {
      title: 'Internship Opportunities',
      description: 'We’re connected to some of the best studios including Spotify, iHeartRadio and  and WNYC. When they need to fill a position, they know we’ve got some of the best and brightest in podcasting. ',
      icon: briefcase,
    },
    {
      title: 'Technology',
      description: 'We’ve got you covered. Enjoy university discounts to many services including B&H, Adobe and more. Not to mention all our affiliates that help provide equipment, editing software and publishing solutions. All included in tuition!',
      icon: money,
    },
  ];

  return (
    <>
    <SectionTitle color="black">Program Benefits</SectionTitle>
    <BenefitsWrapper>
      <div className="benefits-container">
        {benefits.map((benefit, index) => (
          <div className="benefit-tile" key={index}>
            <div className="tile-icon">
              <img src={benefit.icon} alt="Benefit Icon" />
            </div>
            <h3>{benefit.title}</h3>
            <p>{benefit.description}</p>
          </div>
        ))}
      </div>
    </BenefitsWrapper>
    </>
  );
};

export default Benefits;
