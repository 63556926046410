import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { BlogContext, contentfulClient } from '../App';
import { BlogPosts } from '../components/Resources/Data';

const ProgressBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 5px; /* Height of the progress bar */
  background-color: #ff0000; /* Color of the progress bar */
  z-index: 9999;
  transition: width 0.2s ease; /* Transition for smooth width changes */
`;

const SectionHero = styled.img`
  height: 30vh;
  width: 30vh;
  max-width: 100%;
  object-fit: contain;
  display: block;
  margin: 0 auto;
  background-color: #F6EFEB;
`;

const BlogSection = styled.section`
  padding: 0 30%;
  margin: 150px 0;
`;

const BlogTitle = styled.h1`
  font-family: 'Roboto';
  font-size: 2rem;
  margin: 1rem 0;
`;

const BlogBody = styled.div`
  font-size: 16px;
  line-height: 1.6;
  margin-top: 1rem;
  text-align: justify;
`;

const BlogPostPage = () => {
  const [scrollProgress, setScrollProgress] = useState(0);
  const blogPosts = useContext(BlogContext);
  const location = useLocation();
  console.log(location.pathname);
  const [currentblog, setCurrentBlog] = useState<BlogPosts | null>(null);
  const [assets, setAssets] = useState<{ [id: string]: string }>({});

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.body.clientHeight;

    const progress = (scrollY / (documentHeight - windowHeight)) * 100;
    setScrollProgress(progress);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    blogPosts.forEach((post) => {
      contentfulClient
        .getAsset(post.imageURL)
        .then((asset) =>
          setAssets((prevAssets) => ({ ...prevAssets, [post.id]: asset.fields.file.url }))
        )
        .catch((error) => console.error('Error fetching asset:', error));
    });
  }, [blogPosts, contentfulClient]);

  useEffect(() => {
    blogPosts.forEach((post) => {
      const path = location.pathname.split('/');
      console.log(path[path.length - 1]);
      if (post.id === path[path.length - 1]) setCurrentBlog(post);
    });
  }, []);

  return (
    <div>
      <ProgressBar style={{ width: `${scrollProgress}%` }} />
      {currentblog && assets[currentblog.id] && (
        <SectionHero src={assets[currentblog.id]} alt={currentblog.title} />
      )}
      <BlogSection>
        {currentblog && <BlogTitle>{currentblog.title}</BlogTitle>}
        {currentblog && <BlogBody>{currentblog.body}</BlogBody>}
      </BlogSection>
    </div>
  );
};

export default BlogPostPage;
