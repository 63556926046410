// Kathleen Bio
export const Kathleen = `Producer Kathleen Russo is the Director of the Podcast Incubator Program at Stony Brook University. She is the Executive Producer of Hillary Clinton's podcast You and Me Both (recent recipient of a Gracie Award) and Alec Baldwin's podcast Here's The Thing, both on iHeartRadio. She also produced a podcast for Tina Brown called TBD on Wondery, which was nominated one of the best podcasts in 2019 in Cosmopolitan. Kathleen has been a contributor to The Moth, This American Life, and StoryCorps.

Kathleen's past producing credits include two movies with Academy Award winner Steven Soderbergh (Gray's Anatomy and Everything is Going Fine), and an OBIE Award-winning play called Spalding Gray: Stories Left To Tell.`

// Tony Bio
export const Tony = `Tony is a podcast strategist who helped create the dual-semester academic training & development program with Kathie that launched in the fall of 2018, and was a professor/lead instructor in audio storytelling and production during those formative years. Having created and headed up podcasting at Bloomberg News in 2006, he’s also developed podcasts and streaming content for Icahn School of Medicine at Mt. Sinai, and Conde Nast and TIME magazines. He was lead editor of the Peabody Award-winning documentary series "Leonard Bernstein: An American Life," and a producer at the launches of SiriusXM and Sony In-Flight Audio Entertainment. Tony previously served for many years as adjunct professor at Columbia University's Graduate School of Journalism, advisory committee member of the Carnegie Council for Ethics in International Affairs’ Workshop for Ethics in Business, an awards screener for the duPont-Columbia University Awards, and vice president on the board of directors of AIRmedia. He a musician and alumnus of the Berklee College of Music.`

// Frank Bio
export const Frank = `
Frank has been at Stony Brook University for over ten years and currently manages both the audio/visual technology for special events at Stony Brook Southampton and works with the Stony Brook University video production group.

He educates and advises students and professionals about equipment and best practices to make successful productions.

Production coordinator credits include the NYC Marathon, the City Park Foundation’s Summer Stage Concert Series, political debates, comedy shows, ballet performances and film festivals. He is considered a jack of all trades within his industry.  In his spare time, he operates a fully automated timing system for scholastic and collegiate track and field competitions.
`

//Imran Bio
export const Imran = `
Imran Azad is a modern day marketer who brings his love for storytelling and passion for technology together. Originally joining the Podcast Incubator durings it's inaugaraul year in 2018, he has stayed on to function as the marketing director and undergraduate instructor.  Outside of teaching the undergraduate cohort on Monday nights, you can find out what he's been up to [here](https://azad.ventures/).
`

// Veralyn Bio
export const Veralyn = `
I am a Peabody and Edward R. Murrow award-winning journalist, executive producing for WNYC's [The United States of Anxiety](https://www.wnycstudios.org/podcasts/anxiety). And as a journalist who started my career at 16 years-old by going on a [personal investigation into figuring out my legal status](https://www.wnyc.org/story/88736-legal-status/) in this country, my instinct has always been to ask hard questions about the world around me. I’ve done that as host of Slate’s [Represent Rose](https://slate.com/culture/2017/08/recapping-the-bachelorettes-reunion-episode-and-season-finale.html), [The Waves](https://slate.com/human-interest/2018/06/the-waves-on-the-c-word-down-syndrome-and-ali-wong.html), and Brooklyn Deep’s [Third Rail](https://bkdthirdrail.podbean.com/e/third-rail-eps-14-revolutionary-birth/); as a producer, reporter, and editor for [Radio Rookies](https://www.wnyc.org/story/when-we-see-each-other/), [Family Ghosts](https://www.stitcher.com/podcast/panoply/family-ghosts/e/53874013), [The Stakes](https://www.wnycstudios.org/podcasts/the-stakes/episodes/the-stakes-giving-birth-while-black); and now as a senior voice at New York Public Radio.

I live at the intersection of christian, Black, woman, African, immigrant, Bronx-girl, world traveler, and foodie (to name just a few ways I show up in the world), and I’ve worked to become a leader in journalism, so I can make space for voices-- as complicated and multifaceted as mine-- to be heard.
`

// Kerry Bio
export const Kerry = `
I’m a journalist, audio producer, and a champion of new audio storytellers. Previously, I ran the audio program at the Columbia Graduate School of Journalism and was the director of training at PRX (where I developed the Google Podcasts creator program and this [Podcasting 101](https://training.prx.org/programs/google-podcasts-creator-program) video series). I also work at [Spooler](https://www.spooler.fm/), an audio tech start-up. As a producer at Audible in the early 2000s, I created "original, recurrent, downloadable audio" before the first mp3 was attached to an RSS feed. I started in community radio, and I believe audio can create community. I live in Manhattan on Lenapehoking land and use she/her pronouns. [@kerrydonahue](https://twitter.com/kerrydonahue) 
`

// Alan Bio
export const Alan = `
Alan Haburchak is an audio and video journalist, and has been a denizen of the internet for nearly 30 years. He currently is a Senior Producer on both The Refresh from Insider and Money Talks from The Economist podcasts. Previously, Alan served as Director of Audio for Morning Brew and its network of accessible business podcasts. Prior to Morning Brew, he was Manager of Video & Audio Post-Production for BuzzFeed's Platform Studios division, overseeing production for shows and series in partnership with iHeartMedia, Netflix, Facebook Watch, Snapchat, and Twitter. He keeps a spreadsheet rating every podcast he's ever listened to, and is happy to share it with anyone who asks. 
`

// Zach Bio
export const Zach = `
Zach has been a professional audio engineer, editor, mixer and producer for 22 years. He began his career in audio at The Hit Factory recording studio in New York City working with artists like Paul Simon, Rob Thomas, Bjork, and John Legend. After the close of Hit Factory, Zach went on the road mixing live recordings for bands like Pixies, Liars, Alice Cooper and many others. In 2010, Zach transitioned primarily to post production Film, Television, and eventually podcasts. Recent podcast credits include: Here's The Thing with Alec Baldwin, You and Me Both with Hillary Clinton, The West Wing Weekly, Song Exploder, Home Cooking, Art Fraud, and many more.  
`
// Amy Bio
export const Amy = `
In addition to a solid base of studio film and TV credits, Amy Gaipa’s first love of independent and developmental projects began in front of the camera and on stage and continues to this day.  Amy recently received her MFA in Film Directing at SBU as well as serving as  Production Designer and Producer on multiple projects in collaboration with her fellow classmates as well as Donna's House Productions.  Most recently Amy wrapped on producing, production design and acting in the feature film Dirty Rhetoric with Tovah Feldshuh.  Amy teaches undergrad film at SBU and is a member of New York Women In Film and Television (NYWIFT).  Amy was a 2021 NYWIFT scholarship fund recipient for the next generation of women filmmakers and content creators.
`

//Katie Bio
export const Katie = `Katie Bishop is the executive producer for the podcast [Death, Sex & Money](https://www.wnycstudios.org/podcasts/deathsexmoney) from WNYC Studios. Before joining the show in 2014, Katie produced Soundcheck, a daily program about music and the arts. She has also worked for Martha Stewart Living Radio on Sirius XM, Marketplace, Jazz at Lincoln Center Radio, and The State of Things from North Carolina Public Radio.`

//Ashley Bio
export const Ashley = `Ashley began her journalism career in 2002 as an intern at WNYC. For seven years she was a reporter in the New York bureau of Marketplace where she produced news spots, features, and participated in discussions with the show’s hosts. She has also reported for NPR, Voice of America, and several other outlets. Since 2012 Ashley’s been producing and hosting [The Broad Experience](https://www.thebroadexperience.com/), her podcast on women and the workplace -- one of the first shows to focus on that topic. She hosts the Morgan Stanley Ideas Podcast and has also hosted [The Venture](https://gimletmedia.com/shows/the-venture/5wh3xl/introducing-the-venture), a branded podcast for Gimlet Creative. She's also an adjunct professor at Columbia University's Graduate School of Journalism.`

//Allyson Bio
export const Allyson = `Allyson is a journalist whose work often appears on NPR's flagship news programs, and on podcasts including KCRW's Lost Notes and the BBC's Short Cuts.`

//Michael Bio
export const Michael = `Michael MacDonald is a Stony Brook University student-athlete alumnus and current Account Executive at PMM, Podcast Media Marketing, Inc. Michael represents sponsorships within some of the finest Podcast, National Radio and PBS Television Programming under the PMM Inc. umbrella. These include Serial, This American Life, The Joe Rogan Experience, and Armchair Expert with Dax Shepard to name a few. From executing live podcast event sponsorships with on-site activation to host read sponsorships, Michael has a robust industry knowledge and insight into the ever changing and emerging podcast media landscape. `

//Zoe Bio
export const Zoe = `Zoe is an experienced radio and podcast producer with a background in non-profit administration and academic research. She has produced both weekly and daily programs and launched new shows on condensed production timelines. As an adept project manager in both independent and collaborative capacities, Zoe has a proven ability to coordinate multiple projects on concurrent deadlines with efficiency and attention to detail. She brings to the table excellent writing, organization, communication, and problem-solving skills. At core, she considers herself a life-long learner with an insatiable curiosity and a passion for the arts.`

//Eric Bio
export const Eric = `Eric Silver is an award-winning podcast producer, game designer, and teacher based in the great state of New York. He's the Head of Development and co-founder of [Multitude](https://multitude.productions/), an independent podcast collective and production studio in Brooklyn. He’s produced 11 podcasts that have racked up tens of millions of downloads, and developed many more, characterized by strong, original structures and thriving communities. He is recognized as the Best Dungeon Master in Podcasting, running the actual play TTRPG show [Join the Party](https://www.jointhepartypod.com/) for 7 years.`

//Amanda Bio
export const Amanda = `Amanda McLoughlin is the CEO of [Multitude](https://multitude.productions/), an independent podcast collective and production company. A digital creator since 2004 whose career started in finance and business services, she created Multitude in 2018 to support fellow creators in making a living on their own terms. She was named a Forbes 30 Under 30 Media honoree in 2021, and currently co-hosts two podcasts ([Join the Party](https://www.jointhepartypod.com/) and [Spirits](https://spiritspodcast.com/)) and Executive Produces several more. She leads the growing Multitude team as they push for a more transparent and sustainable creator economy.`

//Catherine Bio
export const Catherine = `Catherine Burns is a Peabody Award–-winning director, storytelling midwife, consultant, and public speaker. From 2003 to 2023, she was the artistic director of The Moth, where she was a host and producer of [The Moth Radio Hour](https://themoth.org/radio-hour) and co-author of the New York Times Best Seller [How to Tell a Story](https://www.amazon.com/How-Tell-Story-Essential-Storytelling/dp/0593139003). As one of the lead directors on The Moth’s Mainstage, she has helped many hundreds of people craft their stories, including a New York City sanitation worker, a Nobel Laureate, the Tower of London’s Raven Master, a jaguar tracker, and an exonerated prisoner. She is the editor of the best-selling and critically-acclaimed books The Moth: 50 True Stories, All These Wonders, and Occasional Magic, and an editor of their newest collection, A Point of Beauty. Born and raised in Alabama, she now lives in Brooklyn with her husband and young son. Find her on [Instagram](https://www.instagram.com/thecatherineburns/) or [LinkedIn](https://www.linkedin.com/in/catherine-burns-054838/).`

