import React, { FC } from 'react'
import { useScreenClass } from 'react-grid-system'
import styled from 'styled-components'
import { Button, SectionTitle } from '../shared'

const Section = styled.section<{ isDesktop: boolean }>`
  background-color: ${(props) => props.theme.colors.red};
  height: ${(props) => (props.isDesktop ? '355px' : 'auto')};
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  a {
    margin-bottom: 2rem;
  }
`

const CTA: FC = () => {
  const screenClass = useScreenClass()
  const isDesktop = ['md', 'lg', 'xl'].includes(screenClass)

  return (
    <Section isDesktop={isDesktop}>
      <SectionTitle>
        Creativity is calling you. <br />
        Seize the day!
      </SectionTitle>
      <Button variant="black" path="/apply" size="lg">
        Apply Now
      </Button>
    </Section>
  )
}

export default CTA
