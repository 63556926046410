
import React, { CSSProperties, FC } from 'react'
import { Col, Container, Row, useScreenClass } from 'react-grid-system'
import { Button, ImageBlock, SectionTitle } from '../shared'
import { IGLASS, OEISENBERG, VWILLIAMS } from '../../assets/guests'

const imgStyles: CSSProperties = {
  height: '300px',
}

const Guests: FC = () => {
  const screenClass = useScreenClass()
  const isDesktop = ['lg', 'xl'].includes(screenClass)

  return (
    <section>
      <SectionTitle color="black">Guests</SectionTitle>
      <Container style={{ margin: '4rem auto' }}>
        <Row style={{ marginBottom: '3rem' }}>
          <Col
            xs={12}
            sm={12}
            md={4}
            lg={4}
            style={{ marginBottom: !isDesktop ? '4rem' : undefined }}
          >
            <ImageBlock
              src={IGLASS}
              alt="Ira Glass portrait"
              title="Ira Glass"
              para="Host and Producer of This American Life"
              color="black"
              imgStyles={imgStyles}
            />
          </Col>
          <Col
            xs={12}
            sm={12}
            md={4}
            lg={4}
            style={{ marginBottom: !isDesktop ? '4rem' : undefined }}
          >
            <ImageBlock
              src={OEISENBERG}
              alt="Ophira Eisenberg portrait"
              title="Ophira Eisenberg"
              para="Host of Ask Me Another"
              color="black"
              imgStyles={imgStyles}
            />
          </Col>
          <Col
            xs={12}
            sm={12}
            md={4}
            lg={4}
            style={{ marginBottom: !isDesktop ? '4rem' : undefined }}
          >
            <ImageBlock
              src={VWILLIAMS}
              alt="Veralyn Williams portrait"
              title="Veralyn Williams"
              para="Executive Producer for The United States of Anxiety"
              color="black"
              imgStyles={imgStyles}
            />
          </Col>
        </Row>
        <div style={{ textAlign: 'center' }}>
          <Button variant="red" path="/guests">
            More Guests
          </Button>
        </div>
      </Container>
    </section>
  )
}

export default Guests