import React, { FC } from 'react'
import { useScreenClass } from 'react-grid-system'
import styled from 'styled-components'
import BANNER from '../../assets/hero-banner.png'

const Section = styled.section<{ isDesktop: boolean }>`
  height: ${(props) => (props.isDesktop ? '360px' : '240px')};
  text-align: center;
  background-image: url(${BANNER});
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8rem;

  h2 {
    font-size: ${(props) => (props.isDesktop ? '90px' : '58px')};
    letter-spacing: 2px;
    margin: 0;
    color: white;
  }
`

const SecondaryHero: FC = ({ children }) => {
  const screenClass = useScreenClass()
  const isDesktop = ['lg', 'xl'].includes(screenClass)

  return (
    <Section isDesktop={isDesktop}>
      <h2>{children}</h2>
    </Section>
  )
}

export default SecondaryHero
