import React, { useEffect, useState } from 'react'
import { SectionTitle } from '../shared'
import { Col, Container, Row, useScreenClass } from 'react-grid-system'
import axios from 'axios'

import Tweet from './Tweet'

const SocialPanel = () => {
  const [tweets, setTweets] = useState([])
  useEffect(() => {
    const getTweets = async () => {
      console.log('here')
      try {
        const res = await axios.get('/getTweets')
        console.log(res.data)
        setTweets(res.data)
      } catch (error) {
        console.error(error)
      }
    }

    getTweets()
  }, [])

  return (
    <Container style={{ margin: '4rem auto' }}>
      <SectionTitle color="black"></SectionTitle>
      <Row>
        {tweets.map((tweet) => (
          <Col xs={12} lg={6}>
            <Tweet
              key={tweet.id}
              text={tweet.text}
              createdAt={tweet.created_at}
              profileImage={tweet.profile_image}
              screenName={tweet.screen_name}
              userName={tweet.user_name}
            />
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export default SocialPanel
