import React, { FC, useContext, useEffect, useState } from 'react'
import { BlogContext, contentfulClient } from '../App'
import Grid  from '../components/Resources/Grid'
import { SecondaryHero } from '../components/shared'


const Resources: FC = () => {
const blogPosts=useContext(BlogContext)
  return (
    <>
      <SecondaryHero>Resources</SecondaryHero>
      <Grid data={blogPosts}client={contentfulClient} />
    </>
  )
}

export default Resources
