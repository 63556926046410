import React, { FC, useState } from 'react'
import { Col, Container, Hidden, Row, useScreenClass } from 'react-grid-system'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import { FRows } from './FacultyRows'

interface Prop {
  data: {
    title: string
    faculty: Fellows.Faculty[]
  }
}

const Section = styled.section<{ isDesktop: boolean }>`
  text-align: center;
  background-color: ${(props) => props.theme.colors.red};
  padding: 5rem 0 8rem 0;
  color: white;

  .section-title {
    font-size: ${(props) => (props.isDesktop ? '66px' : '44px')};
    margin-bottom: 4rem;
  }

  h3 {
    font-size: ${(props) => (props.isDesktop ? '24px' : '20px')};
  }

  a {
    color: white;
    text-decoration: underline;
    font-weight: 700;
  }

  p {
    line-height: ${(props) => (props.isDesktop ? '' : '1.6rem')};
  }

  .adjunct-img {
    object-fit: cover;
    margin: 8px;
    cursor: pointer;
    filter: grayscale(83%);
    transition: filter 0.3s;

    &:hover {
      filter: none;
    }
  }

  .adjunct-img.selected {
    filter: none;
  }
`

const SquareContainer = styled.div<{ isDesktop: boolean }>`
  position: relative;

  .adjunct-1 {
    top: ${(props) => (props.isDesktop ? '0' : '2rem')};
    left: ${(props) => (props.isDesktop ? '0' : '14rem')};
  }

  .adjunct-2 {
    top: ${(props) => (props.isDesktop ? '10rem' : '10rem')};
    left: ${(props) => (props.isDesktop ? '14rem' : '22rem')};
  }

  .adjunct-3 {
    top: ${(props) => (props.isDesktop ? '20rem' : '18rem')};
    left: ${(props) => (props.isDesktop ? '0' : '14rem')};
  }

  .adjunct-4 {
    top: ${(props) => (props.isDesktop ? '30rem' : '26rem')};
    left: ${(props) => (props.isDesktop ? '14rem' : '22rem')};
  }

  .selected-container {
    transform: scale(1.12) rotate(45deg);
  }
`

const Square = styled.div`
  transform: scale(0.88) rotate(45deg);
  height: 170px;
  width: 170px;
  overflow: hidden;
  position: absolute;
  transition: all 0.33s;

  &:hover {
    box-shadow: 1px 4px 21px rgba(0, 0, 0, 0.41);
    transform: scale(1.13) rotate(45deg);
  }
`

const Rectangle = styled.div<{ isDesktop: boolean }>`
  height: 525px;
  width: 350px;
  border: 4px solid white;
  position: absolute;
  top: 3rem;
  left: ${(props) => (props.isDesktop ? '0' : '50%')};
  transform: ${(props) => (props.isDesktop ? '' : 'translateX(-50%)')};
`

const FacultyDescription = styled.div`
  h3 {
    font-size: 32px;
  }
  p {
    line-height: 1.6rem;
  }
`

const AdjunctFaculty: FC<Prop> = ({ data }) => {
  const screenClass = useScreenClass()
  const isDesktop = ['lg', 'xl'].includes(screenClass)
  const [currentId, setCurrentId] = useState(data.faculty[0].id)
  const selected =
    data.faculty.find((item) => item.id === currentId) || data.faculty[0]

  const handleOnClick = (id: string) => {
    setCurrentId(id)
  }

  return (
    <Section isDesktop={isDesktop}>
      <Container>
        <h2 className="section-title">Associate Faculty</h2>
        <Row align="center">
          <Hidden xs sm>
            <Col xs={12} sm={12} lg={6} style={{ height: '640px' }}>
              <SquareContainer isDesktop={isDesktop}>
                <Rectangle isDesktop={isDesktop} />
                {data.faculty.map((item, idx) => (
                  <Square
                    className={`adjunct-img-container adjunct-${idx + 1} ${
                      currentId === item.id ? 'selected-container' : ''
                    }`}
                  >
                    <img
                      src={item.image}
                      alt={`${item.name} portrait`}
                      className={`adjunct-img ${
                        currentId === item.id ? 'selected' : ''
                      }`}
                      height={'250'}
                      width={'245'}
                      onClick={() => handleOnClick(item.id)}
                      style={{
                        transform: 'rotate(-45deg)',
                        position: 'absolute',
                        top: '-30%',
                        left: '-30%',
                      }}
                    />
                  </Square>
                ))}
              </SquareContainer>
            </Col>
          </Hidden>
          <Hidden xs sm>
            <Col xs={12} sm={12} lg={6} style={{ textAlign: 'left' }}>
              <FacultyDescription>
                <h3 style={{ marginTop: '8px' }}>{selected.name}</h3>
                <h4 style={{ margin: '0' }}>{selected.title}</h4>
                <ReactMarkdown>{selected.bio}</ReactMarkdown>
              </FacultyDescription>
            </Col>
          </Hidden>
          <Hidden md lg xl>
            <Col xs={12} sm={12}>
              {data.faculty.map((item) => (
                <FRows
                  name={item.name}
                  title={item.title}
                  image={item.image}
                  key={item.id}
                  id={item.id}
                  bio={item.bio}
                />
              ))}
            </Col>
          </Hidden>
        </Row>
      </Container>
    </Section>
  )
}

export default AdjunctFaculty
