import React, { FC } from 'react'
import { Col, Container, Row, useScreenClass } from 'react-grid-system'
import styled from 'styled-components'
import { CTA } from '.'
import { CARD_DATA } from './data'
import LearnCard from './LearnCard'

const Title = styled.h1<{ isDesktop: boolean }>`
  font-size: ${(props) => (props.isDesktop ? '55px' : '42px')};
  text-align: center;
  margin: 6rem 0 3rem 0;
`

const Learn: FC = () => {
  const screenClass = useScreenClass()
  const isDesktop = ['lg', 'xl'].includes(screenClass)

  return (
    <section style={{ position: 'relative' }}>
      <Title isDesktop={isDesktop}>From Concept to Market</Title>
      <Container style={{ maxWidth: '1440px', marginBottom: '100px' }}>
        <Row style={{ marginBottom: '4rem' }}>
          {CARD_DATA.map((item) => (
            <Col xs={12} sm={12} lg={4}>
              <LearnCard
                key={item.title}
                title={item.title}
                description={item.description}
              />
            </Col>
          ))}
        </Row>
      </Container>
      <CTA />
    </section>
  )
}

export default Learn
