/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React,{createContext, useEffect,useState} from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import axios from 'axios'
import { Home, Faculty, Shows, Guests, Apply, Alumni,} from './pages'
import './normalize.css'
import { ScreenClassProvider, setConfiguration } from 'react-grid-system'
import { FormspreeProvider } from '@formspree/react'
import RouteWrapper from './components/shared/RouteWrapper'
import ScrollToTop from './scrollToTop'
import Resources from './pages/Resources'
import BlogPostPage from './pages/BlogPostPage'
import {createClient} from 'contentful'
import { BlogPosts } from './components/Resources/Data'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

setConfiguration({ maxScreenClass: 'xl' })
export const BlogContext=createContext<BlogPosts[]>([]);
export const contentfulClient=createClient({
  space:'2gv8suilbgb4', accessToken:'vq16Pr7DZIfNvpRXdOatHHADLwL_kCXhEacC7h-vbD8'
})

function App() {
  // development only
  // axios.defaults.baseURL = 'http://localhost:5001/podcast-fellows/us-central1';
  axios.defaults.baseURL = 'https://us-central1-fellows-forms.cloudfunctions.net';

  const [blogPosts,setBlogPosts]=useState<BlogPosts[]>([])

let contentfulResponse;
useEffect(()=>{
    contentfulClient.getEntries('Resources')
        .then(response=>{
            contentfulResponse = response;
            console.log ({contentfulResponse});
            contentfulResponse.items.forEach((item)=>{
                const post={
                id:item.sys.id,
                    title:item.fields.title,
                    body:documentToReactComponents((item.fields.body)),
                    imageURL:item.fields.thumbnail.sys.id,
                    date: item.fields.date,
                    author: item.fields.author
                }

                setBlogPosts(oldBlogPosts => [...oldBlogPosts, post])
             })
        })
        .catch(e=>console.error('error'+e))
},[])


  return (
    <BlogContext.Provider value={blogPosts}>
    <ScreenClassProvider>
      <FormspreeProvider project="1650066375743372547">
        <Router>
          <ScrollToTop />
          <Switch>
            <RouteWrapper path="/apply" component={Apply} />
            <RouteWrapper path="/faculty" component={Faculty} />
            <RouteWrapper path="/resources" component={Resources} />
            <RouteWrapper path="/guests" component={Guests} />
            <RouteWrapper path="/alumni" component={Alumni}/>
            <RouteWrapper path="/shows" component={Shows} />
            <RouteWrapper path="/blog/:postId" component={BlogPostPage} />
            <RouteWrapper path="/" component={Home} />
          </Switch>
        </Router>
      </FormspreeProvider>
    </ScreenClassProvider>
    </BlogContext.Provider>
  )
}

export default App
