import React, { FC } from 'react'
import { Col, Container, Row, useScreenClass } from 'react-grid-system'
import { SectionTitle, ImageBlock, Button } from '../shared'
import styled from 'styled-components'
import NTYA from '../../assets/shows/NTYA.jpg'
import TBTB from '../../assets/shows/TBTB.jpg'
import SPESHFX from '../../assets/shows/SpeshFX.jpg'
import INTHY from '../../assets/shows/INTHY.jpg'

const Shows: FC = () => {
  const screenClass = useScreenClass()
  const isDesktop = ['lg', 'xl'].includes(screenClass)

  const Section = styled.section`
    background-color: ${(props) => props.theme.colors.darkGray};
    height: ${isDesktop ? '700px' : 'auto'};
    width: 100%;
    padding-top: ${isDesktop ? '20rem' : '2rem'};
    margin-top: ${isDesktop ? '-285px' : 'auto'};
    padding-bottom: 4rem;
  `

  const imgStyles = {
    width: isDesktop ? '' : '100%',
    margin: isDesktop ? '' : '0 auto',
  }

  return (
    <Section>
      <SectionTitle descr="Check out the shows that have been produced through our fellowship">
        Our Shows
      </SectionTitle>
      <Container style={{ marginTop: '4rem' }}>
        <Row>
          <Col xs={12} sm={12} md={6} lg={3}>
            <ImageBlock
              src={NTYA}
              alt="Not That You Asked Podcast Cover"
              title="Not That You Asked"
              imgStyles={imgStyles}
              link="https://cms.megaphone.fm/channel/notthatyouasked"
            />
          </Col>
          <Col xs={12} sm={12} md={6} lg={3}>
            <ImageBlock
              src={TBTB}
              alt="This Book That Book Podcast Cover"
              title="This Book That Book"
              imgStyles={imgStyles}
              link="https://cms.megaphone.fm/channel/thisbookthatbook"
            />
          </Col>
          <Col xs={12} sm={12} md={6} lg={3}>
            <ImageBlock
              src={SPESHFX}
              alt="SpeshFX Podcast Cover"
              title="SpeshFX"
              imgStyles={imgStyles}
              link="https://cms.megaphone.fm/channel/speshfx?selected=STONY7817442896"
            />
          </Col>
          <Col xs={12} sm={12} md={6} lg={3}>
            <ImageBlock
              src={INTHY}
              alt="Placeholder"
              title="It's Nice to Hear You"
              imgStyles={imgStyles}
              link="https://www.itsnicetohearyou.com/"
            />
          </Col>
        </Row>
        <div style={{ textAlign: 'center', marginTop: '3rem' }}>
          <Button variant="red" path="/shows">
            More Shows
          </Button>
        </div>
      </Container>
    </Section>
  )
}

export default Shows