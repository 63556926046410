import React, { FC } from 'react'
import { Grid } from '../components/Guests'
import { ALUMNI_DATA } from '../components/Alumni/data'
import { SecondaryHero } from '../components/shared'

const Alumni: FC = () => {
  return (
    <>
      <SecondaryHero>Alumni</SecondaryHero>
      <Grid data={ALUMNI_DATA} xl={6} lg={12} md={12} />
    </>
  )
}

export default Alumni
