import React, { FC } from 'react'
import {
  Affilates,
  Contact,
  CTA,
  Guests,
  HeroWrapper,
  Shows,
  Slider,
  SocialPanel,
  Benefits,
} from '../components/Home'


const Home: FC = () => {
  return (
    <>
      <HeroWrapper />
      <Slider />
      <Shows />
      <Guests />
      <Benefits />
      <CTA /> 
      <SocialPanel />
      <Contact />
      <Affilates />
    </>
  )
}
export default Home
