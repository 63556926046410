import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';


interface Props {
  name: string;
  title: string;
  bio: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);

  .content {
    display: flex;
    align-items: flex-start;
  }

  .text {
    margin-left: 20px; /* Add margin between image and text */
    text-align: left;
  }

  h3 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 12px;
    color: #1c1e1f;
  }

  p {
    margin: 0;
    font-size: 18px;
    line-height: 1.6;
    color: #4b4f56;
    margin-bottom: 24px;
  }

  a {
    color: #990000;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .bio {
    font-size: 18px;
    line-height: 1.6;
    color: #1c1e1f;
    margin-bottom: 24px;
  }

  .image {
    max-width: 100%;
    height: auto;
    margin-bottom: 16px;
    border-radius: 8px; /* Add border radius to image */
  }
`;



// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const AlumniBio = ({ name, title, bio }: Props) => {

  return (
    <Container>
      <h3>{name}</h3>
      <p>{title}</p>
      <ReactMarkdown className="bio" linkTarget={'_blank'}>
        {bio}
      </ReactMarkdown>
    </Container>
  );
};

export default AlumniBio;
