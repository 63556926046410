import React, { FC } from 'react'
import { Col, Row, useScreenClass } from 'react-grid-system'
import { SectionTitle } from '../shared'
import INPERSON from '../../assets/In-Person.jpg'
import HAMPTON from '../../assets/Southhampton.jpg'
import ZOOM from '../../assets/Zoom.png'
import styled from 'styled-components'

const Title = styled.h3<{ isDesktop: boolean }>`
  font-size: ${(props) => (props.isDesktop ? '30px' : '24px')};
  text-align: center;
  letter-spacing: 2px;
`

const Locations: FC = () => {
  const screenClass = useScreenClass()
  const isDesktop = ['lg', 'xl'].includes(screenClass)

  const ColStyles = {
    marginBottom: isDesktop ? '' : '3rem',
  }

  return (
    <section style={{ marginBottom: '6rem' }}>
      <SectionTitle color="black">Multiple Locations</SectionTitle>
      <Row style={{ margin: isDesktop ? '3rem 4rem' : '2rem 1rem' }}>
        <Col xs={12} sm={12} lg={4} style={ColStyles}>
          <Title isDesktop={isDesktop}>Remote</Title>
          <img
            src={ZOOM}
            alt={'Remote Location'}
            width={'100%'}
            height={isDesktop ? 300 : 'auto'}
            style={{ objectFit: 'cover' }}
          />
        </Col>
        <Col xs={12} sm={12} lg={4} style={ColStyles}>
          <Title isDesktop={isDesktop}>Southampton, NY</Title>
          <img
            src={HAMPTON}
            alt={'Southampton Location'}
            width={'100%'}
            height={isDesktop ? 300 : 'auto'}
            style={{ objectFit: 'cover' }}
          />
        </Col>
        <Col xs={12} sm={12} lg={4} style={ColStyles}>
          <Title isDesktop={isDesktop}>New York, NY</Title>
          <img
            src={INPERSON}
            alt={'In person Location'}
            width={'100%'}
            height={isDesktop ? 300 : 'auto'}
            style={{ objectFit: 'cover' }}
          />
        </Col>
      </Row>
    </section>
  )
}

export default Locations
