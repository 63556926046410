import React, { FC } from 'react'
import { SecondaryHero } from '../components/shared'
import { Grid } from '../components/Shows'
import { SHOW_DATA } from '../components/Shows/data'

const Shows: FC = () => {
  return (
    <>
      <SecondaryHero>Shows</SecondaryHero>
      <Grid shows={SHOW_DATA} />
    </>
  )
}

export default Shows
