/* eslint-disable no-constant-condition */
import React, { FC } from 'react'
import styled from 'styled-components'
import { useScreenClass } from 'react-grid-system'

interface Prop {
  open: boolean
}
const Menu: FC<Prop> = ({ open }) => {
  const screenClass = useScreenClass()
  const isDesktop = ['lg', 'xl'].includes(screenClass)

  const StyledMenu = styled.nav<{ open: boolean }>`
    display: ${(props) => (props.open ? 'flex' : 'none')};
    flex-direction: column;
    justify-content: flex-start;
    background: white;
    height: 100vh;
    text-align: left;
    padding: 5rem 1rem;
    position: absolute;
    top: 0;
    right: 0;
    transition: transform 0.45s ease-in-out;
    width: ${!isDesktop && '60%'};
    transform: ${(props) =>
      props.open ? 'translateX(0)' : 'translateX(100%)'};
    z-index: 99;
    box-shadow: ${(props) => (props.open ? '0 0 22px 4px #333' : '')};

    a {
      font-size: ${isDesktop ? '2rem' : '1.25rem'};
      text-transform: uppercase;
      padding: 14px 2rem;
      font-weight: bold;
      letter-spacing: 0.25rem;
      color: ${(props) => props.theme.colors.red};
      text-decoration: none;
      transition: all 0.3s;
      margin: 1rem 0;

      &:hover {
        background-color: ${(props) => props.theme.colors.red};
        color: white;
      }
    }
  `
  return (
    <StyledMenu open={open}>
      <a href="https://docs.google.com/document/d/e/2PACX-1vQ8PIc9oa9DRRNByVriyNoZSRuim7Ew1yr1SBzhQ-XZlBRv4kd4q-I1KNZTh44F97Xe6RnK-yH_-Af1/pub">Syllabus</a>
      <a href="/shows">Shows</a>
      <a href="/faculty">Faculty</a>
      <a href="/alumni">Alumni</a>
      <a href="/resources">Resources</a>
      <a href="/guests">Guests</a>
      <a href="/#contact">Contact</a>
      <a href="/apply">About Us</a>
    </StyledMenu>
  )
}

export default Menu
