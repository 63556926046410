import { nanoid } from 'nanoid'
import { RichText } from './RichTexts'
import {
  KATHIE,
  IMRAN,
  FRANK,
  KATIE,
  MICHAEL,
  KERRY,
  ZACH,
  AMANDA,
  ERIC,
  CATHERINE
} from '../../assets/faculty'
import { ZSAUNDERS } from '../../assets/guests'

export const FACULTY_DATA = {
  faculty: {
    title: '',
    faculty: [
      {
        id: nanoid(),
        name: 'Kathleen Russo',
        title: 'Director',
        image: KATHIE,
        bio: RichText.Kathleen,
      },
      {
        id: nanoid(),
        name: 'Kerry Donahue',
        title: 'Professor',
        image: KERRY,
        bio: RichText.Kerry,
      },
      {
        id: nanoid(),
        name: 'Eric Silver',
        title: 'Professor',
        image: ERIC,
        bio: RichText.Eric,
      },
      {
        id: nanoid(),
        name: 'Amanda McLoughlin',
        title: 'Professor',
        image: AMANDA,
        bio: RichText.Amanda,
      },
      {
        id: nanoid(),
        name: 'Frank Imperiale',
        title: 'Technology Director',
        image: FRANK,
        bio: RichText.Frank,
      },
      {
        id: nanoid(),
        name: 'Zach McNees',
        title: 'Audio Engineer',
        image: ZACH,
        bio: RichText.Zach,
      },
      {
        id: nanoid(),
        name: 'Catherine Burns',
        title: 'Professor',
        image: CATHERINE,
        bio: RichText.Catherine,
      },
      {
        id: nanoid(),
        name: 'Imran Azad',
        title: 'Marketing Director',
        image: IMRAN,
        bio: RichText.Imran,
      },

      
    ],
  },
  adjunctFaculty: {
    title: 'Adjunct Faculty',
    faculty: [
      
      {
        id: nanoid(),
        name: 'Zoe Saunders',
        title: '',
        image: ZSAUNDERS,
        bio: RichText.Zoe,
      },
     
      {
        id: nanoid(),
        name: 'Katie Bishop',
        title: '',
        image: KATIE,
        bio: RichText.Katie,
      },
      {
        id: nanoid(),
        name: 'Michael MacDonald',
        title: '',
        image: MICHAEL,
        bio: RichText.Michael,
      },
    ],
  },
}
