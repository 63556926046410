import React, { FC } from 'react'
import { Route } from 'react-router-dom'
import Layout from '../Layout'
interface Prop {
  component: React.ElementType
  path: string
}

const RouteWrapper: FC<Prop> = ({ component: Component, ...rest }) => {
  if (!Component) return null
  return (
    <Route
      {...rest}
      render={(...routeProps) => (
        <Layout>
          <Component {...routeProps} />
        </Layout>
      )}
    ></Route>
  )
}

export default RouteWrapper
