import React, { FC } from 'react'
import styled from 'styled-components'
import { useScreenClass } from 'react-grid-system'

interface Prop {
  color?: 'white' | 'black'
  descr?: string
}
const SectionTitle: FC<Prop> = ({ children, color = 'white', descr }) => {
  const screenClass = useScreenClass()
  const isDesktop = ['md', 'lg', 'xl'].includes(screenClass)

  const Title = styled.h2<{ color: string }>`
    font-size: ${isDesktop ? '52px' : '44px'};
    text-align: center;
    margin: ${isDesktop ? '3rem 0' : '5rem 0 4rem 0'};
    color: ${(props) => (props.color === 'white' ? 'white' : 'black')};
  `

  const Description = styled.p<{ color: string }>`
    text-align: center;
    color: ${(props) => (props.color === 'white' ? 'white' : 'black')};
    margin: 0 2rem;
  `
  return (
    <>
      <Title color={color}>{children}</Title>
      {descr && <Description color={color}>{descr}</Description>}
    </>
  )
}

export default SectionTitle
