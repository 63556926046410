import React, { FC } from 'react'
import { Col, useScreenClass } from 'react-grid-system'
import styled from 'styled-components'

const Section = styled.section<{ isDesktop: boolean }>`
  background-color: #828282; /* Replace with your hex color */
  height: ${(props) => (props.isDesktop ? '380px' : 'auto')};
  padding: ${(props) => (props.isDesktop ? '4rem' : '7rem 1rem')};
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: ${(props) => (props.isDesktop ? '' : 'column')};

  .small-text {
    position: absolute;
    right: ${(props) => (props.isDesktop ? '0.5rem' : '')};
    bottom: 0;
    color: ${(props) => props.theme.colors.lightGray};
    font-size: 14px;
  }
`

const Content = styled.div<{ isDesktop: boolean }>`
  text-align: center;
  color: white;
  h3 {
    font-size: ${(props) => (props.isDesktop ? '40px' : '36px')};
    margin: ${(props) => (props.isDesktop ? '1rem 0' : '0.5rem 0')};
  }
  .content-text {
    font-size: ${(props) => (props.isDesktop ? '26px' : '20px')};
  }
  .content-large-text {
    font-size: ${(props) => (props.isDesktop ? '48px' : '30px')};
    margin: ${(props) => (props.isDesktop ? '26px 0' : '20px 0')};
  }
`

const ProgramDetails: FC = () => {
  const screenClass = useScreenClass()
  const isDesktop = ['lg', 'xl'].includes(screenClass)

  const ColStyles = {
    marginBottom: isDesktop ? '' : '3rem',
  }

  return (
    <Section isDesktop={isDesktop}>
      <Col xs={12} sm={12} lg={4} style={ColStyles}>
        <Content isDesktop={isDesktop}>
          <h3>It's Diverse</h3>
          <p className="content-large-text">We Go Global</p>
          <p>Remote learning is an option</p>
        </Content>
      </Col>
      <Col xs={12} sm={12} lg={4} style={ColStyles}>
        <Content isDesktop={isDesktop}>
          <h3>Program Length</h3>
          <p className="content-large-text">8 Months</p>
          <p>September - May</p>
        </Content>
      </Col>
      <Col xs={12} sm={12} lg={4} style={ColStyles}>
        <Content isDesktop={isDesktop}>
          <h3>It's Affordable</h3>
          <p className="content-large-text">$8,200</p>
          <p>Financial Aid is available.</p>
        </Content>
      </Col>
    </Section>
  )
}

export default ProgramDetails
