// Hero.tsx
import React, { FC } from 'react'
import { useScreenClass } from 'react-grid-system'
import { Button } from '../shared'
import styled from 'styled-components'
import HERO from '../../assets/hero.png'

const Section = styled.div<{ isDesktop: boolean }>`
  height: ${(props) => (props.isDesktop ? '700px' : '500px')};
  padding: ${(props) => (props.isDesktop ? '4rem' : '1rem')};
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`

const Title = styled.h1<{ isDesktop: boolean }>`
  position: relative;
  font-size: ${(props) => (props.isDesktop ? '64px' : '44px')};
  color: white;
  z-index: 10;
  width: ${(props) => (props.isDesktop ? '125%' : '100%')};
`

// New SubTitle styled component
const SubTitle = styled.h2<{ isDesktop: boolean }>`
  font-size: ${(props) => (props.isDesktop ? '24px' : '18px')};
  color: #f0f0f0;
  margin: ${(props) => (props.isDesktop ? '1rem 0 2rem 0' : '0.75rem 0 1.5rem 0')};
  font-weight: 400;
  letter-spacing: 1px;
`

const Rectangle = styled.div<{ isDesktop: boolean }>`
  position: absolute;
  height: 540px;
  width: 360px;
  border: 3px solid white;
  filter: drop-shadow(0px 15px 6px rgba(255, 68, 68, 0.76));
  right: 4rem;
  top: 5rem;
  opacity: 0.88;
  z-index: 3;
`

const Image = styled.img<{ isDesktop: boolean }>`
  height: ${(props) => (props.isDesktop ? '760px' : '400px')};
  width: auto;
  object-fit: cover;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 4;
`

const Hero: FC = () => {
  const screenClass = useScreenClass()
  const isDesktop = ['lg', 'xl'].includes(screenClass)

  return (
    <Section isDesktop={isDesktop}>
      <div>
        <Title isDesktop={isDesktop}>
          SBU Podcast Incubator
        </Title>
        {/* Added SubTitle below Title */}
        <SubTitle isDesktop={isDesktop}>
          Launch a show people want.
        </SubTitle>
        <Button variant="black" size={isDesktop ? 'lg' : 'sm'} path="/apply">
          Early Apply
        </Button>
      </div>
      <div
        style={{
          position: 'relative',
          marginRight: isDesktop ? '5rem' : '1rem',
          width: '33%',
          height: '100%',
          display: isDesktop ? 'block' : 'none', // Changed from '' to 'block' for clarity
        }}
      >
        <Image src={HERO} alt={'Hero Collage'} isDesktop={isDesktop} />
        <Rectangle isDesktop={isDesktop} />
      </div>
    </Section>
  )
}

export default Hero
