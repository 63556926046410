interface CardProps {
  title: string
  description: string
}

export const CARD_DATA: CardProps[] = [
  {
    title: 'Pitching a Podcast',
    description:
      'Your words have impact. Clearly and effectively pitch anyone your podcast: potential guests, a production partner, an advertiser, a network.',
  },
  {
    title: 'Storytelling with Sound',
    description:
      'Imagination is powerful. Write to activate your listeners’ interest, to evoke cinematic soundscapes and to illustrate exceptional thoughts.',
  },
  {
    title: 'Interviewing & Hosting',
    description:
      'Conversations arise from curiosity. Engage in meaningful discussions that matter and uncover ideas that need to be revealed.',
  },
  {
    title: 'Recording & Editing',
    description:
      'The sound is the story. Make capturing it a second nature reflex and make each voice understood and honored.',
  },
  {
    title: 'Mixing & Sound Design',
    description:
      'What’s heard can be seen. Sculpt and express your audio creation with artistry, learning to make choices that resonate, and underscore your authentic voice.',
  },
  {
    title: 'Branding & Marketing',
    description:
      'The listener must know that your podcast exists. Promote it to reach your audience: from tag line to artwork to social campaigns, this is your calling card to succeed.',
  },
]
