import React, { FC } from 'react';
import { Row, useScreenClass } from 'react-grid-system';
import { SectionTitle } from '../shared';
import styled from 'styled-components';
import FULLSCOPE from '../../assets/FullScope.png';
import HINDENBURG from '../../assets/Hindenburg.png';
import MEGAPHONE from '../../assets/Megaphone.png';
import RIVERSIDE from '../../assets/riverside.png';
import SAMSON from '../../assets/Samson.png';
import WLIW from '../../assets/wliwFM.png';
import PODFEST from '../../assets/PODFEST.png';
import LIBSYN from '../../assets/libsynlogo.png';
import ONAIR from '../../assets/On_Air_Logo.png';

const Image = styled.img<{ isDesktop: boolean }>`
  width: 225px; /* Set a fixed width for all images */
  height: auto; /* Maintain aspect ratio */
  margin: 0 3rem; /* Adjust margins as needed */
`;

const Section = styled.section<{ isDesktop: boolean }>`
  margin-top: 10rem;
  height: auto;
  padding: ${(props) => (props.isDesktop ? '1rem 0 8rem 0' : '3rem 0')};

  a {
    width: ${(props) => (props.isDesktop ? '' : '60%')};
    margin: ${(props) => (props.isDesktop ? '' : ' 0.5rem 0')};
  }
`;

const Affiliates: FC = () => {
  const screenClass = useScreenClass();
  const isDesktop = ['md', 'lg', 'xl'].includes(screenClass);

  return (
    <Section isDesktop={isDesktop}>
      <SectionTitle color="black">Sponsors</SectionTitle>
      <Row align="center" justify="center" style={{ margin: '4rem auto' }}>
        <a href="https://libsyn.com/" target="_blank" rel="noopener">
          <Image isDesktop={isDesktop} src={LIBSYN} alt="Libsyn Logo" />
        </a>
        <a href="http://www.samsontech.com/" target="_blank" rel="noopener">
          <Image isDesktop={isDesktop} src={SAMSON} alt="Samson Logo" />
        </a>
        <a href="https://www.descript.com/" target="_blank" rel="noopener">
          <Image isDesktop={isDesktop} src={FULLSCOPE} alt="FullScope Logo" />
        </a>
      </Row>
      <Row align="center" justify="center" style={{ margin: '1rem auto' }}>
        <a href="https://www.onairfest.com/" target="_blank" rel="noopener">
          <Image isDesktop={isDesktop} src={ONAIR} alt="On Air Fest Logo" />
        </a>
        <a href="https://www.wliw.org/radio/" target="_blank" rel="noopener">
          <Image isDesktop={isDesktop} src={WLIW} alt="WLIWFM Logo" />
        </a>
        <a href="https://hindenburg.com/" target="_blank" rel="noopener">
          <Image isDesktop={isDesktop} src={HINDENBURG} alt="Hindenburg Logo" />
        </a>
      </Row>
      <Row
        align="center"
        justify="center"
        style={{ margin: '2rem auto', textAlign: 'center' }}
      >
        <a href="https://podfestexpo.com" target="_blank" rel="noopener">
          <Image
            isDesktop={isDesktop}
            src={PODFEST}
            alt="Podfest Logo"
          />
        </a>
      </Row>
    </Section>
  );
};

export default Affiliates;
