import React, { FC } from 'react'
import Hero from './Hero'
import Services from './Services'
import styled from 'styled-components'
import { useScreenClass } from 'react-grid-system'

const Section = styled.section<{ isDesktop: boolean }>`
  background-color: ${(props) => props.theme.colors.darkRed};
  height: ${(props) => (props.isDesktop ? '1700px' : 'auto')};
  overflow: hidden;
`

const HeroWrapper: FC = () => {
  const screenClass = useScreenClass()
  const isDesktop = ['lg', 'xl'].includes(screenClass)

  return (
    <Section isDesktop={isDesktop}>
      <Hero />
      <div style={{ margin: isDesktop ? '10rem 4rem 0' : '1rem' }}>
        <Services />
      </div>
    </Section>
  )
}

export default HeroWrapper
