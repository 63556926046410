import React, { CSSProperties, FC } from 'react'
import styled from 'styled-components'

interface Prop {
  src: string
  alt?: string
  title?: string
  para?: string
  color?: 'white' | 'black'
  link?: string
  style?: CSSProperties
  imgStyles?: CSSProperties
}

const Container = styled.div<{ color: string }>`
  display: flex;
  flex-direction: column;
  align-content: center;
  color: ${(props) => (props.color === 'white' ? 'white' : 'black')};
  text-align: center;

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  h3 {
    margin-bottom: 12px;
    letter-spacing: 1px;
    font-size: 1.25rem;
  }
  p {
    margin: 0;
    font-size: 18px;
  }
`

const ImageBlock: FC<Prop> = ({
  src,
  alt,
  title,
  para,
  color = 'white',
  link,
  style,
  imgStyles,
}) => {
  return (
    <Container color={color} style={style}>
      {link ? (
        <a href={link} target="_blank" rel="noopener noreferrer">
          <img src={src} alt={alt} style={imgStyles} />
        </a>
      ) : (
        <img src={src} alt={alt} style={imgStyles} />
      )}

      <h3 style={{ width: '80%', margin: '0 auto' }}>{title}</h3>
      {para && <p>{para}</p>}
    </Container>
  )
}

export default ImageBlock
