import React, { FC } from 'react'
import styled from 'styled-components'

interface Prop {
  open: boolean
  // eslint-disable-next-line no-unused-vars
  setOpen: (value: boolean) => void
}
const Hamburger: FC<Prop> = ({ open, setOpen }) => {
  const StyledBurger = styled.button<{ open: boolean }>`
    position: absolute;
    top: 3.5%;
    right: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 999;

    &:focus {
      outline: none;
    }

    .hamburger-line {
      width: 2rem;
      height: 0.25rem;
      background: black;
      border-radius: 10px;
      transition: all 0.3s linear;
      position: relative;
      transform-origin: 1px;
    }

    .hamburger-line.one {
      transform: ${(props) => (props.open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    .hamburger-line.two {
      opacity: ${(props) => (props.open ? '0' : '1')};
      transform: ${(props) =>
        props.open ? 'translateX(20px)' : 'translateX(0)'};
    }

    .hamburger-line.three {
      transform: ${(props) => (props.open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  `

  return (
    <StyledBurger open={open} onClick={() => setOpen(!open)}>
      <div className="hamburger-line one" />
      <div className="hamburger-line two" />
      <div className="hamburger-line three" />
    </StyledBurger>
  )
}

export default Hamburger
