import React, { FC } from 'react'
import { useScreenClass } from 'react-grid-system'
import styled from 'styled-components'

const Container = styled.div<{ isDesktop: boolean }>`
  width: ${(props) => (props.isDesktop ? '650px' : '80%')};
  height: 180px;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
  background-color: white;
  text-align: center;
  padding: 1rem;
  position: absolute;
  bottom: -12rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;

  h2 {
    margin-bottom: 3rem;
  }
`
const Button = styled.button<{ variant: string; size: string }>`
  padding: ${(props) =>
    props.size === 'lg' ? '1.25rem 3.25rem' : '12px 2.5rem'};
  border: none;
  background-color: ${(props) =>
    props.variant === 'red'
      ? props.theme.colors.red
      : props.theme.colors.black};
  color: white;
  cursor: pointer;
  font-size: ${(props) => (props.size === 'lg' ? '22px' : '16px')};
  font-weight: 600;
  letter-spacing: 1.75px;
  transition: background-color 0.3s, transform 0.3s;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: ${(props) =>
      props.variant === 'red' ? '#B80000' : '#393939'};
    transform: scale(1.05);
  }
`

const CTA: FC = () => {
  const screenClass = useScreenClass()
  const isDesktop = ['lg', 'xl'].includes(screenClass)

  return (
    <Container isDesktop={isDesktop}>
      <h2>Submit by August 15th to start your podcasting career!</h2>
      <a href="https://stonybrookuniversity.co1.qualtrics.com/jfe/form/SV_3aqRXNG82X43wDI">
        <Button variant="red" size="lg">
          Start Application
        </Button>
      </a>
    </Container>
  )
}

export default CTA
