import React, { FC } from 'react'
import { Row, Col, useScreenClass } from 'react-grid-system'
import styled from 'styled-components'
import Logo from '../../assets/StonyWhite.png'
import FB from '../../assets/FacebookIcon.png'
import IG from '../../assets/InstagramIcon.png'
import Background from '../../assets/blackBG.png'

const FooterContainer = styled.footer<{ isDesktop: boolean }>`
  background-image: url(${Background});
  background-size: cover;
  padding: ${(props) => (props.isDesktop ? ' 4rem' : '3rem 2rem')};
  height: ${(props) => (props.isDesktop ? '320px' : 'auto')};
  color: white;
`

const Link = styled.a`
  margin-bottom: 1rem;
  color: white;
  display: block;
  font-size: 1.25rem;
`

const Footer: FC = () => {
  const screenClass = useScreenClass()
  const isDesktop = ['lg', 'xl'].includes(screenClass)

  return (
    <FooterContainer isDesktop={isDesktop}>
      <Row justify="between" align="start">
        <div
          style={{
            display: 'flex',
            flexDirection: isDesktop ? 'row' : 'column',
          }}
        >
          <Col
            xs={12}
            sm={12}
            style={{ marginBottom: isDesktop ? '' : '4rem' }}
          >
            <img
              src={Logo}
              alt="Audio Podcast SBU Logo"
              style={{ width: isDesktop ? '325px' : '90%' }}
            />
            <h2>Podcast Incubator</h2>
            <div style={{ marginTop: '2rem' }}>
              <p>
                Kathleen Russo, <em>Co-Director</em>
              </p>
              <p>
                <a href="tel:6318829881" style={{ color: 'white' }}>
                  631-882-9881
                </a>
              </p>
              <a
                href="mailto:kathleen.russo@stonybrook.edu"
                style={{ color: 'white' }}
              >
                kathleen.russo@stonybrook.edu
              </a>
            </div>
          </Col>
          <Col
            xs={12}
            sm={12}
            style={{ marginBottom: isDesktop ? '' : '4rem' }}
          >
            <h2 style={{ fontSize: '1rem', fontWeight: 'normal' }}>
              Quick Links
            </h2>
            <Link href="/apply">About Us</Link>
            <Link href="/shows">Our Shows</Link>
            <Link href="/faculty">Faculty</Link>
            <Link href="/resources">Resources</Link>
            <Link href="/guests">Guests</Link>
            <Link href="/#contact">Contact</Link>
          </Col>
        </div>
        <div>
          <a
            href="https://www.instagram.com/sbupodcastincubator/?hl=en"
            target="_blank"
            rel="noopener"
            style={{ margin: '0 1.5rem' }}
          >
            <img src={IG} alt="Instagram Logo" height={46} />
          </a>
          <a
            href="https://www.facebook.com/sbupodcastincubator"
            target="_blank"
            rel="noopener"
            style={{ margin: '0 1.5rem' }}
          >
            <img src={FB} alt="Facebook Logo" height={46} />
          </a>
        </div>
      </Row>
    </FooterContainer>
  )
}

export default Footer
