import React from 'react'
import { ThemeProvider } from 'styled-components'

const theme = {
  colors: {
    red: '#990000',
    brightRed: '#D52027',
    darkRed: '#6B000D',
    black: '#060606',
    white: '#fff',
    darkGray: '#4B4B4B',
    mediumGray: '#828282',
    lightGray: '#BEBEBE',
    transluscentGray: '#BEBEBE35'
  },
  background: '#f3f3f3',
}

const Theme: React.FC = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
)

export default Theme
