import React, { FC } from 'react'
import Theme from '../../theme'
import { Navbar, Footer } from '../shared'
import GlobalStyles from '../../GlobalStyles'

const Layout: FC = ({ children }) => {
  return (
    <Theme>
      <GlobalStyles />
      <header>
        <Navbar />
      </header>
      <main>{children}</main>
      <Footer />
    </Theme>
  )
}

export default Layout
