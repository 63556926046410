import React, { FC } from 'react'
import { AdjunctFaculty, FacultyRow } from '../components/Faculty'
import { FACULTY_DATA } from '../components/Faculty/data'
import { SecondaryHero } from '../components/shared'

const Faculty: FC = () => {
  return (
    <>
      <SecondaryHero>Faculty</SecondaryHero>
      <FacultyRow data={FACULTY_DATA.faculty} />
      <AdjunctFaculty data={FACULTY_DATA.adjunctFaculty} />
    </>
  )
}

export default Faculty
