import React, { FC, useState } from 'react'
import { useForm, ValidationError } from '@formspree/react'
import styled from 'styled-components'
import axios from 'axios'

const IForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 2rem auto;

  label {
    font-size: 14px;
    color: white;
  }

  input {
    height: 28px;
    padding: 4px;
  }

  input,
  textarea {
    border: none;
    margin: 8px 0;
  }

  button {
    margin-top: 1rem;
  }
`

const Button = styled.button<{ variant: string; size: string }>`
  padding: ${(props) =>
    props.size === 'lg' ? '1.25rem 3.25rem' : '12px 2.5rem'};
  border: none;
  background-color: ${(props) =>
    props.variant === 'red'
      ? props.theme.colors.red
      : props.theme.colors.black};
  color: white;
  cursor: pointer;
  font-size: ${(props) => (props.size === 'lg' ? '22px' : '16px')};
  font-weight: 600;
  letter-spacing: 1.75px;
  transition: backgound-color 0.3s;

  a {
    color: white;
    text-decoration: none;
  }

  &:hover {
    background-color: ${(props) =>
      props.variant === 'red' ? '#B80000' : '#393939'};
  }
`

const SuccessMessage = styled.div`
  background-color: ${(props) => props.theme.colors.red};
  height: 80px;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    color: white;
    font-size: 20px;
  }
`

const Error = styled.div`
  margin-top: 0.25rem;
  padding: 0.5rem;
  text-align: center;
  background: #B80000;
  color: white;
`

const Form: FC = () => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [formFields, setFormFields] = useState({
    fullName: '',
    email: '',
    message: ''
  });

  const onChange = (e) => {
    setFormFields({
      ...formFields,
      [e.target.name]: e.target.value
    });
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    if(!formFields.email || !formFields.fullName || !formFields.message){
      setError(true);
      return;
    }

    try {
      const { fullName, email, message } = formFields;
      await axios.post('/sendEmail', { fullName, email, message });
      setError(false);
      setSuccess(true);
      setFormFields({
        fullName: '',
        email: '',
        message: ''
      })
      setTimeout(() => setSuccess(false), 3000);
    } catch (error) { 
      console.error(error)
    }
  }

  if (success) {
    return (
      <SuccessMessage>
        <p>Thanks for your message!</p>
      </SuccessMessage>
    )
  }

  return (
    <IForm id="contact-form" onSubmit={onSubmit}>
      <label htmlFor="fullName">Full Name*</label>
      <input id="fullName" name="fullName" type="text" value={formFields.fullName} onChange={onChange} />
      {/* <ValidationError prefix="Name" field="Full Name" errors={state.errors} /> */}
      <label htmlFor="email">Email Address*</label>
      <input id="email" name="email" type="email" value={formFields.email} onChange={onChange} />
      {/* <ValidationError prefix="Email" field="Email" errors={state.errors} /> */}
      <label htmlFor="message">Message*</label>
      <textarea id="message" name="message" style={{ height: '100px' }} value={formFields.message} onChange={onChange} />
      {/* <ValidationError prefix="Message" field="Message" errors={state.errors} /> */}
      <input type="text" name="_gotcha" style={{ display: 'none' }} />
      {
        error && (
          <Error>
            <p>Please fill in all fields</p>
          </Error>
        )
      }
      <Button
        type="submit"
        variant="red"
        size="sm"
        style={{ textAlign: 'center', marginTop: '2rem' }}
      >
        Submit
      </Button>
    </IForm>
  )
}

export default Form
