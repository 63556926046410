import React, { FC } from 'react'
import { Col, Container, Row, useScreenClass } from 'react-grid-system'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'

interface Prop {
  data: {
    title: string
    faculty: Fellows.Faculty[]
  }
}

export const Section = styled.section<{ isDesktop: boolean }>`
  text-align: center;
  padding: ${(props) => (props.isDesktop ? '4rem 2rem' : '2rem 1rem')};
  background-color: #f9f9f9;

  .section-title {
    font-size: ${(props) => (props.isDesktop ? '40px' : '32px')};
    margin-bottom: ${(props) => (props.isDesktop ? '3rem' : '1rem')};
    font-weight: bold;
    color: #333;
  }

  h3 {
    font-size: ${(props) => (props.isDesktop ? '28px' : '22px')};
    margin: 0.75rem 0;
    color: #444;
  }

  h4 {
    font-size: ${(props) => (props.isDesktop ? '20px' : '18px')};
    color: #777;
    margin-bottom: 1rem;
  }

  p {
    line-height: 1.8;
    color: #555;
    text-align: justify;
  }

  a {
    color: #0066cc;
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: #004999;
    }
  }
`

export const StyledImage = styled.img`
  width: 100%;
  max-width: 350px;
  height: auto;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
`

export const FRows: FC<Fellows.Faculty> = ({ name, title, image, bio }) => {
  return (
    <Row style={{ marginBottom: '4rem', alignItems: 'center' }}>
      <Col xs={12} sm={12} lg={6}>
        <StyledImage
          src={image}
          alt={`${name} portrait`}
        />
      </Col>
      <Col xs={12} sm={12} lg={6} style={{ textAlign: 'left', padding: '1rem' }}>
        <h3>{name}</h3>
        <h4>{title}</h4>
        <ReactMarkdown>{bio}</ReactMarkdown>
      </Col>
    </Row>
  )
}

const FacultyRows: FC<Prop> = ({ data }) => {
  const screenClass = useScreenClass()
  const isDesktop = ['lg', 'xl'].includes(screenClass)

  return (
    <Section isDesktop={isDesktop}>
      <h2 className="section-title">{data.title}</h2>
      <Container style={{ maxWidth: '1200px' }}>
        {data.faculty.map((item) => (
          <FRows
            name={item.name}
            title={item.title}
            image={item.image}
            key={item.id}
            id={item.id}
            bio={item.bio}
          />
        ))}
      </Container>
    </Section>
  )
}

export default FacultyRows
