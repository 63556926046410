import React, { FC } from 'react'
import { Col, Container, Hidden, Row, useScreenClass } from 'react-grid-system'
import styled from 'styled-components'
import CONTACT from '../../assets/contact.jpg'
import Form from './Form'

const Section = styled.section<{ isDesktop: boolean }>`
  padding: ${(props) => (props.isDesktop ? '4rem 2rem 0rem 2rem' : '3rem 0')};
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: ${(props) => (props.isDesktop ? '' : `url(${CONTACT})`)};
  background-position: 50% 50%;
  background-size: cover;
`

const Contact: FC = () => {
  const screenClass = useScreenClass()
  const isDesktop = ['md', 'lg', 'xl'].includes(screenClass)

  const Title = styled.h2`
    font-size: ${isDesktop ? '40px' : '36px'};
    color: white;
    text-align: center;
  `

  return (
    <Section id="contact" isDesktop={isDesktop}>
      <Container
        style={{
          backgroundColor: isDesktop ? '#828282' : 'rgba(34,34,34,0.75)',
          height: isDesktop ? '' : '550px',
        }}
      >
        <Row>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            <Hidden xs sm>
              <img
                src={CONTACT}
                alt="Podcast studio"
                style={{
                  width: '100%',
                  height: isDesktop ? '550px' : 'auto',
                  objectFit: 'cover',
                }}
              />
            </Hidden>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6}>
            <Title>Have a question?</Title>
            <Form/>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default Contact
