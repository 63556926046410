import React, { FC } from 'react'
import { useScreenClass } from 'react-grid-system'
import styled from 'styled-components'

const Card = styled.div<{ isDesktop: boolean }>`
  height: ${(props) => (props.isDesktop ? '200px' : 'auto')};
  width: auto;
  padding: ${(props) => (props.isDesktop ? '2rem 1rem' : '1rem')};
  background-color: white;
  margin: 0.5rem auto;

  h3 {
    color: ${(props) => props.theme.colors.red};
    font-size: ${(props) => (props.isDesktop ? '30px' : '28px')};
    margin: 16px 0;
  }

  p {
    line-height: 1.5rem;
    font-size: ${(props) => (props.isDesktop ? '16px' : '14px')};
  }
`

interface Prop {
  title: string
  description: string
}
const LearnCard: FC<Prop> = ({ title, description }) => {
  const screenClass = useScreenClass()
  const isDesktop = ['lg', 'xl'].includes(screenClass)

  return (
    <Card isDesktop={isDesktop}>
      <h3>{title}</h3>
      <p>{description}</p>
    </Card>
  )
}

export default LearnCard
