import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`

  body {
    background-color: #f3f3f3;
    font-family: 'Roboto', sans-serif;
  }


  h1, h2, h3, h4, h5, h6 {
    font-family: 'Nunito Sans', sans-serif;
  }
`
export default GlobalStyles
