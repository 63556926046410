import React, { FC } from 'react'
import { Learn, Locations, ProgramDetails } from '../components/Apply'

const Apply: FC = () => {
  return (
    <>
      <Learn />
      <ProgramDetails />
      <Locations />
    </>
  )
}

export default Apply
