import React from 'react';
import { Col, Row } from 'react-grid-system';
import styled from 'styled-components';
import Linkify from 'react-linkify';

const TweetBody = styled.div`
  width: 100%;
  border-radius: 5px;
  background: ${props => props.theme.colors.transluscentGray};
  margin-bottom: 2rem;
  padding: 1.5rem 2rem;
  box-sizing: border-box;

  h2{
    margin-top: 0;
    margin-bottom: 0.25rem;
  }

  a{
    color: ${props => props.theme.colors.red};
    font-weight: 500;
  }

  img{
    border-radius: 50%;
    width: 3.5rem;
  }

  p{
    margin: 2rem 0;
    font-size: 1.1rem;
  }

  span{
    font-weight: 500;
    font-size: 0.9rem;
    color: ${props => props.theme.colors.darkGray};
  }
`;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Tweet = ({ text, userName, profileImage, createdAt, screenName }) => {
  const date = new Date(createdAt);
  return (
    <TweetBody>
      <Row style={{ textAlign: 'center' }}>
        <Col xs={4} md={2} >
          <img src={profileImage} alt="StonyBrook Twitter Logo" />
        </Col>
        <Col xs={8} md={10} style={{ textAlign: 'left' }} >
         <h2>{userName}</h2>
         <a href={`https://twitter.com/${screenName}`}>@{screenName}</a>
         <Linkify>
           <p>{text}</p>
         </Linkify>
         <span>{date.toLocaleString()}</span>
        </Col>
      </Row>
    </TweetBody>
  )
}

export default Tweet
