// Grid.tsx
import { Asset, ContentfulClientApi } from 'contentful';
import React, { FC, useEffect, useState } from 'react';
import { Col, Container, Row, useScreenClass } from 'react-grid-system';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { BlogPosts } from './Data';

interface Props {
  data: BlogPosts[];
  client: ContentfulClientApi;
}

const Section = styled.section`
  text-align: center;
  margin: 2rem auto; /* Added vertical margin for better spacing */
  max-width: 1440px;
  padding: 0 1rem; /* Added horizontal padding for better content alignment */

  @media screen and (min-width: 768px) {
    padding: 0 2rem; /* Increased padding on larger screens for content spacing */
  }
`;


const BlogGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Responsive grid */
  gap: 1.5rem;
  margin-top: 2rem;

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const BlogCard = styled.div`
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  padding: 1.5rem;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const BlogImage = styled.img`
  max-width: 100%;
  height: auto;
  margin-bottom: 1rem;
`;

const BlogTitle = styled.h2`
  font-size: 1.25rem;
  margin-bottom: 0.75rem;
`;

const Grid: FC<Props> = ({ data, client }) => {
  const [assets, setAssets] = useState<{ [id: string]: string }>({});

  useEffect(() => {
    data.forEach((post) => {
      client
        .getAsset(post.imageURL)
        .then((asset) => setAssets((prevAssets) => ({ ...prevAssets, [post.id]: asset.fields.file.url })))
        .catch((error) => console.error('Error fetching asset:', error));
    });
  }, [data, client]);

  // Sort the data based on the newest posts first
  const sortedData = [...data].sort((a, b) => parseInt(b.id) - parseInt(a.id));

  return (
    <Section>
      <BlogGrid>
        {sortedData.map((post) => (
        <Link key={post.id} to={{ pathname: `/blog/${post.id}` }}>
          <BlogCard key={post.id}>
            {assets[post.id] && <BlogImage src={assets[post.id]} alt={post.title} />}
            <BlogTitle>{post.title}</BlogTitle>
          </BlogCard>
        </Link>
        ))}
      </BlogGrid>
    </Section>
  );
};

export default Grid;
