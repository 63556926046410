import React, { FC } from 'react'
import { useScreenClass } from 'react-grid-system'
import styled from 'styled-components'
import { ImageBlock } from '../shared'

interface Prop {
  shows: {
    title: string
    shows: {
      title: string
      image: string
      link: string
    }[]
  }[]
}

const IGrid = styled.div<{ isDesktop: boolean }>`
  display: grid;
  align-items: flex-start;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: ${(props) => (props.isDesktop ? '2rem' : '1rem')};
  row-gap: ${(props) => (props.isDesktop ? '3rem' : '2rem')};
  margin: ${(props) => (props.isDesktop ? '4rem auto' : '2rem auto')};
  max-width: 1200px;
  padding: 0 1rem;
`

const Section = styled.section<{ isDesktop: boolean }>`
  text-align: center;
  padding: ${(props) => (props.isDesktop ? '6rem 2rem' : '4rem 1rem')};
  background-color: #f9f9f9;

  .section-title {
    font-size: ${(props) => (props.isDesktop ? '48px' : '32px')};
    margin-bottom: ${(props) => (props.isDesktop ? '4rem' : '2rem')};
    font-weight: bold;
    color: #333;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    display: block;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 1;
    }
  }
`

const Grid: FC<Prop> = ({ shows }) => {
  const screenClass = useScreenClass()
  const isDesktop = ['lg', 'xl'].includes(screenClass)

  const allShows = shows.flatMap((year) => year.shows)

  return (
    <Section isDesktop={isDesktop}>
      <IGrid isDesktop={isDesktop}>
        {allShows.map((show) => (
          <ImageWrapper key={show.title} onClick={() => window.open(show.link, '_blank')}>
            <img src={show.image} alt={`${show.title} Podcast Cover`} />
            <div className="overlay">Click to Listen</div>
          </ImageWrapper>
        ))}
      </IGrid>
    </Section>
  )
}

export default Grid
